import React, { Component } from "react"

import { TiArrowSortedUp } from "react-icons/ti"
import { IconContext } from "react-icons";

import scrollTo from 'gatsby-plugin-smoothscroll';

class ScrollUpButton extends Component {

    constructor() {
        super();

        this.buttonRef = React.createRef();

        this.callScrollFunction = this.callScrollFunction.bind(this);
        this.mouseDidEnter = this.mouseDidEnter.bind(this);
        this.mouseDidExit = this.mouseDidExit.bind(this);
    }

    mouseDidEnter(e) {
        this.buttonRef.current.style.backgroundColor = '#ab9b8e'
    }

    mouseDidExit(e) {
        this.buttonRef.current.style.backgroundColor = '#b1a296'
    }

    callScrollFunction() {
        scrollTo('#top')
    }

    render() {
        if (this.props.windowWidth > 500 & this.props.scrollPosition > 0.28) {

            var opacityAmount = (this.props.scrollPosition - 0.28) * 10

            return(
                <div style = {{...buttonDiv, opacity: opacityAmount}}
                 onClick = {this.callScrollFunction} 
                 onMouseEnter = {this.mouseDidEnter} 
                 onMouseLeave = {this.mouseDidExit}
                 ref = {this.buttonRef}
                 >
                    <TiArrowSortedUp size = {'30'} style = {{position: 'absolute', left: '8px', bottom: '14px' }} />
                    <p style = {buttonText} >
                        To Top    
                    </p>
                </div> 
            )
        } else {
            return (
                <> </>
            )
        }
        
    }
}

const buttonDiv = {
    position: 'fixed',
    height: '60px',
    width: '120px',
    color: 'white',
    backgroundColor: '#b1a296',
    borderRadius: '6px',
    right: '70px',
    bottom: '60px',
    fontFamily: 'Avant Garde',
    fontSize: '22px',
    textAlign: 'center',
    paddingTop: '18px',
    zIndex: '1000000',
    cursor: 'pointer'
}

const buttonText = {
    position: 'relative',
    fontWeight: '600',
    left: '10px'
}
export default ScrollUpButton
import React, { Component } from "react"

import { FaYoutube } from "react-icons/fa"
import { BsChevronRight } from "react-icons/bs"

function RenderMedia({media, windowWidth}) {
    if (windowWidth > 550) {
        return(
            <>
            <div style = {mediaElementVideo} >
                <iframe
                    src={media.link}
                    title={media.title}
                    style={embeddedVideoStyle}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                />
            </div>
            <div style = {mediaElementText} >
            <a href = {media.link} target = "_blank" style = {{textDecoration: 'none', color: 'black'}}>
                <p style = {mediaTitle}>
                    {media.title}
                </p>
                <p style = {mediaLink}>
                    View on YouTube <BsChevronRight 
                                style = {{ 
                                    height: '16px', 
                                    position: 'relative', 
                                    top: '2px', 
                                    right: '5px',
                                    lineHeight: '0px' }} />
                </p>
            </a>
            </div>
            </>
        )
    } else {
        return(
            <>
            <div style = {mediaElementVideoMobile} >
                <iframe
                    src={media.link}
                    title={media.title}
                    style={embeddedVideoStyleMobile}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                />
            </div>
            <div style = {mediaElementText} >
            <a href = {media.link} target = "_blank" style = {{textDecoration: 'none', color: 'black', margin: '0'}}>
                <p style = {mediaTitleMobile}>
                    {media.title}
                </p>
                <p style = {mediaLinkMobile}>
                    View on YouTube <BsChevronRight 
                                style = {{ 
                                    height: '16px', 
                                    position: 'relative', 
                                    top: '2px', 
                                    right: '5px',
                                    lineHeight: '0px' }} />
                </p>
            </a>
            </div>
            </>
        )
    }
}

const mediaElementText = {
    minHeight: '80px'
}

const mediaTitle = {
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
    textAlign: 'left',
    margin: '2px 0px 4px 0px'
}

const mediaTitleMobile = {
    ...mediaTitle,
    fontSize: '14px',
    lineHeight: '18px',
}

const mediaLink = {
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    color: '#7395AE',
    lineHeight: '20px',
    margin: '0'
}

const mediaLinkMobile = {
    ...mediaLink,
    fontSize: '14px',
}

const mediaElementVideo = {
    maxWidth: '300px',
    maxHeight: '150px',
    margin: '0px 20px 10px 0px',
}

const mediaElementVideoMobile = {
    ...mediaElementVideo,
    width: '100px',
    maxHeight: '44px'
}

const embeddedVideoStyle = {
    width: '100%',
}

const embeddedVideoStyleMobile = {
    width: '100px',
    height: '60px',
}

export default RenderMedia

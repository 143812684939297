import React, { Component } from "react"

import { Spring } from 'react-spring/renderprops'

import headshot from '../../images/headshot.jpg'

import squiggle from '../../images/squiggle.png'

import cv from '../../files/cv.pdf'

class BioCVButton extends Component {

    constructor(props) {
        super(props);

        this.mouseDidEnter = this.mouseDidEnter.bind(this);
        this.mouseDidExit = this.mouseDidExit.bind(this);
        
        this.textRef = React.createRef();
    }

    mouseDidEnter() {
        this.textRef.current.style.color = '#666'
    }

    mouseDidExit() {
        this.textRef.current.style.color = 'black'
    }

    render() {
        if (this.props.windowWidth > 500) {
            return (
                <div style={buttonStyle}>
                    <a href={cv} target="_blank" style = {{ color: 'black' }} onMouseOver = {this.mouseDidEnter} onMouseOut = {this.mouseDidExit} >
                        <div style = {squiggleHolder}>
                            <img src = {squiggle} alt = "Squiggle" style = {squiggleStyle} />
                            <p style = {buttonText} ref = {this.textRef} >
                                View CV
                            </p>
                        </div>
                    </a>
                </div>
            )
        } else {
            return (
                <div style={buttonStyleMobile}>
                    <a href={cv} target="_blank" style = {{ color: 'black' }} onMouseOver = {this.mouseDidEnter} onMouseOut = {this.mouseDidExit} >
                        <div style = {squiggleHolderMobile}>
                            <img src = {squiggle} alt = "Squiggle" style = {squiggleStyleMobile} />
                            <p style = {buttonText} ref = {this.textRef} >
                                View CV
                            </p>
                        </div>
                    </a>
                </div>
            )
        }
    }
}

const buttonStyle = {
    height: '60px',
    width: '120px',
    fontFamily: 'Avant Garde',
    fontSize: '22px',
    fontWeight: '600',
    position: 'relative',
    cursor: 'pointer',
}

const buttonStyleMobile = {
    ...buttonStyle,
    height: '40px',
    width: '80px',
    fontSize: '20px',
}

const squiggleHolder = {
    width: '120px',
    position: 'relative'
}

const squiggleHolderMobile = {
    ...squiggleHolder,
    width: '80px',
}

const squiggleStyle = {
    position: 'absolute',
    height: '60px',
    top: '0px',
    right: '25px'
}

const squiggleStyleMobile = {
    ...squiggleStyle,
    height: '50px',
    right: '0px'
}

const buttonText = {
    zIndex: '10',
    position: 'absolute',
    top: '10px',
    left: '0px',
    textAlign: 'center'
}

export default BioCVButton
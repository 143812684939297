import React, { Component } from "react"
import SpotifyPlayer from 'react-spotify-player'

import uproot from '../images/uproot.jpg'
import curvedArrow from '../images/curved-arrow.png'

import { FaChevronRight } from "react-icons/fa"

const descriptionText = "In UPROOT I engage in a conversation with people who have left their home countries and came to live in the US. I talk to women and men who feel uprooted and whose lives unfold between cultures. We talk about the challenges and opportunities that each of these women and men have faced in their journey to becoming multicultural citizens in the American workspace and in their communities, and we reflect on how those experiences have shaped their understanding of the US and their respective countries."

class Podcast extends Component {

    constructor(props) {
        super(props);

        this.mouseDidEnter = this.mouseDidEnter.bind(this);
        this.mouseDidExit = this.mouseDidExit.bind(this);

        this.preventDragHandler = this.preventDragHandler.bind(this);
    }

    mouseDidEnter(e) {
        e.target.style.opacity = '0.9'
    }

    mouseDidExit(e) {
        e.target.style.opacity = '1.0'
    }

    preventDragHandler = (e) => {
        e.preventDefault();
    }
    
    render() {
        if (this.props.windowWidth > 1080) {
            return (
                <>
                <div style = {sectionWrapper} id = "podcast">
                    <div style = {sectionStyle}>
                        <h1 style={headerText}>
                            UPROOT Podcast
                        </h1>
                        <div style = {mainContainerStyle} >
                            <div style = {imageContainerStyle}>
                                <a href = "https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix" target = "_blank">
                                    <img src = {uproot} alt = "Uproot Podcast" style = {imageStyle} onMouseOver = {this.mouseDidEnter} onMouseOut = {this.mouseDidExit}  />     
                                </a>
                            </div>
                            <div style = {divider} />
                            <div style = {rightBoxStyle}>
                                <div style = {textBoxStyle}>
                                    <p style={mainText}>
                                        {descriptionText}
                                    </p>
                                </div>

                                <div style = {embedBoxStyle}>
                                    <p style = {featureText}>
                                        Featured Episode
                                    </p>
                                    <img src = {curvedArrow} style = {arrowStyle} onDragStart={this.preventDragHandler} />
                                    <SpotifyPlayer
                                        uri = 'https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix'
                                        size = {{ width: '100%',
                                                height: '200px',
                                                borderRadius: '6px' }}
                                        view = {'list'}
                                        theme = { 'black' }
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        } else if (this.props.windowWidth > 630 & this.props.windowWidth <= 1080 ) {
            return(
                <>
                <div style = {sectionWrapper} id = "podcast">
                    <div style = {sectionStyle}>
                        <h1 style={headerText}>
                            UPROOT Podcast
                        </h1>
                        <div style = {mainContainerStyleMobile} >
                            <div style = {imageContainerStyle}>
                                <a href = "https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix" target = "_blank">
                                    <img src = {uproot} alt = "Uproot Podcast" style = {imageStyle} onMouseOver = {this.mouseDidEnter} onMouseOut = {this.mouseDidExit}  />     
                                </a>
                            </div>
                            
                            <div style = {rightBoxStyle}>
                                <div style = {textBoxStyle}>
                                    <p style={mainText}>
                                        {descriptionText}
                                    </p>
                                </div>

                                <div style = {embedBoxStyle}>
                                    <p style = {featureText}>
                                        Featured Episode
                                    </p>
                                    <img src = {curvedArrow} style = {arrowStyle} onDragStart={this.preventDragHandler} />
                                    <SpotifyPlayer
                                        uri = 'https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix'
                                        size = {{ width: '100%',
                                                height: '160px',
                                                borderRadius: '6px' }}
                                        view = {'list'}
                                        theme = { 'black' }
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        } else {
            return(
                <>
                <div style = {sectionWrapperMobile} id = "podcast">
                    <div style = {sectionStyle}>
                        <h1 style={headerTextMobile}>
                            UPROOT Podcast
                        </h1>
                        <div style = {mainContainerStyleMobile} >
                            <div style = {imageContainerStyle}>
                                <a href = "https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix" target = "_blank">
                                    <img src = {uproot} alt = "Uproot Podcast" style = {imageStyle} onMouseOver = {this.mouseDidEnter} onMouseOut = {this.mouseDidExit}  />     
                                </a>
                            </div>
                            
                            <div style = {rightBoxStyle}>
                                <div style = {textBoxStyle}>
                                    <p style={mainTextMobile}>
                                        {descriptionText}
                                    </p>
                                </div>

                                <div style = {embedBoxStyle}>
                                    <SpotifyPlayer
                                        uri = 'https://open.spotify.com/show/1HsLjyN9uZiXdVgQK21Iix'
                                        size = {{ width: '100%',
                                                height: '160px',
                                                borderRadius: '6px' }}
                                        view = {'list'}
                                        theme = { 'black' }
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        }
    }
}
 
const sectionWrapper = {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    padding: '30px 80px 40px 80px',
    backgroundColor: '#3b576b',
    color: 'white',
    flexShrink: '1'
}

const sectionWrapperMobile = {
    ...sectionWrapper,
    padding: '30px 20px 40px 20px',
}

const sectionStyle = {
    margin: '0px auto 0px auto',
}

const headerText = {
    fontFamily: 'Avant Garde',
    fontSize: '36px',
    margin: '20px 0px 40px 0px',
    lineHeight: '36px'
}

const headerTextMobile = {
    ...headerText, 
    fontSize: '32px',
    marginBottom: '30px'
}

const mainContainerStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
}

const mainContainerStyleMobile = {
    display: 'flex',
    flexFlow: 'column nowrap',
}

const imageContainerStyle = {
    maxWidth: '360px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    flexShrink: '1',
}

const imageStyle = {
    width: '100%'
}

const divider = {
    width: '160px',
    height: '100%',
    flexShrink: '1',
    flexGrow: '1'
}

const textBoxStyle = {
    marginBottom: '20px',
    maxWidth: '900px'
}

const mainText = {
    fontFamily: 'Caslon',
    fontSize: '20px',
    textAlign: 'left'
}

const mainTextMobile = {
    ...mainText,
    fontSize: '18px',
    lineHeight: '22px'
}

const rightBoxStyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    flexShrink: '1',
}

const embedBoxStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
    position: 'relative',
    width: '100%'
}

const featureText = {
    fontFamily: 'Depressionist',
    fontSize: '28px',
    textAlign: 'center',
    color: '#B1A296',
    cursor: 'default'
}

const arrowStyle = {
    height: '60px',
    position: 'absolute',
    top: '60px',
    left: '60px'
}

export default Podcast

import React, { Component } from "react"

import data from '../data/data.json'

import { FaYoutube } from "react-icons/fa"
import { BsChevronRight } from "react-icons/bs"

function RenderEssay({essay, type, windowWidth}) {

    let linkText;

    if (type == 'essay') {
        linkText = 'Read More'
    } else {
        linkText = 'Listen'
    }

    if (windowWidth > 500) {
        return(
            <>
            <div style = {essayElementText} >
            <a href = {essay.link} target = "_blank" style = {{textDecoration: 'none', color: 'black', margin: '0'}}>
                <p style = {essayTitle}>
                    {essay.title}
                </p>
                <p style = {essayPublication}>
                    Published in {essay.publication} on {essay.date}
                </p>
                <div style = {ecLink}>
                    <p style = {essayLink}>
                        {linkText} <BsChevronRight 
                                    style = {{ 
                                        height: '16px', 
                                        position: 'relative', 
                                        top: '2px', 
                                        right: '4px' }} />
                    </p>
                </div>
            </a>
            </div>
            </>
        )
    } else {
        return(
            <>
            <div style = {essayElementText} >
            <a href = {essay.link} target = "_blank" style = {{textDecoration: 'none', color: 'black', margin: '0'}}>
                <p style = {essayTitleMobile}>
                    {essay.title}
                </p>
                <p style = {essayPublicationMobile}>
                    Published in {essay.publication} on {essay.date}
                </p>
                <div style = {ecLink}>
                    <p style = {essayLinkMobile}>
                        Read More <BsChevronRight 
                                    style = {{ 
                                        height: '12px', 
                                        position: 'relative', 
                                        top: '1px', 
                                        right: '5px' }} />
                    </p>
                </div>
            </a>
            </div>
            </>
        )
    }
}

const essayElementText = {
    width: '100%',
    height: '99%',
}

const essayTitle = {
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
    textAlign: 'left',
    position: 'relative',
    margin: '0',
    marginBottom: '2px'
}

const essayTitleMobile = {
    ...essayTitle,
    fontSize: '14px',
    lineHeight: '18px',
    marginBottom: '4px'
}

const essayPublication = {
    color: '#777',
    position: 'relative',
    margin: '0',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Avant Garde',
    fontStyle: 'italic',
    fontWeight: '600',
    marginBottom: '0px',
    textAlign: 'left'
}

const essayPublicationMobile = {
    ...essayPublication,
    fontSize: '14px',
    lineHeight: '18px'
}

const ecLink = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    width: '100%',
    marginLeft: '10px'
}

const essayLink = {
    position: 'relative',
    color: '#7395AE',
    margin: '0',
    marginTop: '10px',
    fontFamily: 'Avant Garde',
    fontSize: '16px',
}

const essayLinkMobile = {
    ...essayLink,
    fontSize: '14px'
}

export default RenderEssay

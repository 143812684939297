import React, { Component } from "react"
import { Spring } from "react-spring/renderprops"

import headshot from "../../images/headshot.jpg"

import BioCVButton from "./bioCVButton"
import BioGSButton from "./bioGSButton"

import cambridge from "../../images/mosaic/cambridge.jpg"
import oxford from "../../images/mosaic/oxford.png"
import jop from "../../images/mosaic/jop.png"
import scid from "../../images/mosaic/scid.gif"
import lapas from "../../images/mosaic/lapas.jpg"
import rafs from "../../images/mosaic/rafs.jpg"
import larr from "../../images/mosaic/larr.jpg"

function RenderBio({ bioText, windowWidth }) {
  if (windowWidth > 1165) {
    return (
      <div style={sectionStyle}>
        <div style={textBoxStyle}>
          <h1 style={taglineStyle}>
            Subnational Politics, Democracy, Authoritarianism
          </h1>
          <p style={mainText}>{bioText}</p>
          <div style={buttonWrapperStyle}>
            <BioCVButton windowWidth={windowWidth} />
            <BioGSButton windowWidth={windowWidth} />
          </div>
        </div>
        <div style={rightBoxStyle}>
          <img src={headshot} alt="Agustina Giraudy" style={imageStyle} />
          <div style={accoladesStyle}>
            <img
              src={cambridge}
              style={mosaicOne}
              alt="Cambridge University Press"
            />
            <img src={oxford} style={mosaicOne} alt="Oxford University Press" />
            <img src={jop} style={mosaicOne} alt="The Journal of Politics" />
            <img
              src={scid}
              style={mosaicOne}
              alt="Studies in Comparative International Development"
            />
            <img
              src={lapas}
              style={mosaicOne}
              alt="Latin American Politics and Society"
            />
            <img
              src={rafs}
              style={mosaicOne}
              alt="Regional and Federal Studies"
            />
            <img
              src={larr}
              style={mosaicTwo}
              alt="Latin American Research Review"
            />
          </div>
        </div>
      </div>
    )
  } else if ((windowWidth <= 1165) & (windowWidth > 500)) {
    return (
      <div style={sectionStyleMobile}>
        <div style={topBoxMobile}>
          <img src={headshot} alt="Agustina Giraudy" style={imageStyleMobile} />
        </div>
        <div style={bottomBoxMobile}>
          <div style={textBoxMobile}>
            <h1 style={taglineStyle}>
              Subnational Politics, Democracy, Authoritarianism
            </h1>
            <p style={mainTextMedium}>{bioText}</p>
          </div>
          <div style={buttonWrapperMobile}>
            <BioCVButton />
            <BioGSButton />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div style={sectionStyleMobile}>
        <div style={topBoxMobile}>
          <img src={headshot} alt="Agustina Giraudy" style={imageStyleMobile} />
        </div>
        <div style={bottomBoxMobile}>
          <div style={textBoxMobile}>
            <h1 style={taglineStyleMobile}>
              Subnational Politics, Democracy, Authoritarianism
            </h1>
            <p style={mainTextMobile}>{bioText}</p>
          </div>
          <div style={buttonWrapperMobile}>
            <BioCVButton />
            <BioGSButton />
          </div>
        </div>
      </div>
    )
  }
}

class Bio extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let bioText = (
      <>
        I am a Professor of Political Science at{" "}
        <a href="https://www.american.edu/sis/" target="_blank" style={bioLink}>
          American University
        </a>
        . My research seeks to uncover the causes and implications of the
        unequal territorial access and exercise of citizens' political, social,
        economic, and civil rights. My work addresses debates on subnational
        (and national) democratic and semi-authoritarian regimes, subnational
        politics, unequal access to and provision of public goods, and
        institutional integrity/accountability.
        <br />
        <br />
        My research has been published or is forthcoming in the Journal of
        Politics, Journal of Politics in Latin America, Studies in Comparative
        International Development, Latin American Politics and Society, Latin
        American Research Review, Publius: The Journal of Federalism, Journal of
        Democracy (en Español), Perspectives on Politics, Regional and Federal
        Studies, Bulletin of Latin American Research, Revista de Ciencia
        Política (Chile), Cambridge University Press, Oxford University Press,
        among others.
      </>
    )

    return <RenderBio bioText={bioText} windowWidth={this.props.windowWidth} />
  }
}

const sectionStyle = {
  maxWidth: "1160px",
  margin: "22px auto 0px auto",
  padding: "60px 0px 40px 0px",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
}

const sectionStyleMobile = {
  ...sectionStyle,
  padding: "0px 0px 0px 0px",
}

const taglineStyle = {
  lineHeight: "38px",
  textAlign: "left",
  fontSize: "30px",
  fontWeight: "600",
  textAlign: "left",
  margin: "4px 0px 8px 0px",
}

const taglineStyleMobile = {
  ...taglineStyle,
  fontSize: "24px",
  lineHeight: "24px",
  textAlign: "left",
  margin: "10px 0px 18px 0px",
}

const mainText = {
  fontFamily: "Caslon",
  fontSize: "20px",
  textAlign: "left",
}

const mainTextMedium = {
  ...mainText,
  textAlign: "left",
}

const mainTextMobile = {
  ...mainText,
  textAlign: "left",
  fontSize: "18px",
  lineHeight: "22px",
}

const textBoxStyle = {
  width: "70%",
}

const textBoxMobile = {
  width: "92%",
}

const rightBoxStyle = {
  width: "22%",
}

const topBoxMobile = {
  width: "100%",
  display: "flex",
  flexFlow: "row nowrap",
}

const bottomBoxMobile = {
  width: "100%",
  display: "flex",
  flexFlow: "column nowrap",
  alignItems: "center",
}

const imageStyle = {
  position: "relative",
  height: "240px",
  width: "100%",
  objectFit: "cover",
  borderRadius: "4px",
  top: "10px",
  marginBottom: "10px",
}

const imageStyleMobile = {
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  margin: "20px auto 20px auto",
}

const buttonWrapperStyle = {
  width: "600px",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  margin: "80px auto 60px auto",
}

const buttonWrapperMobile = {
  width: "80%",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  margin: "20px auto 44px auto",
}

const accoladesStyle = {
  height: "100px",
  width: "100%",
  fontFamily: "Caslon",
  fontSize: "20px",
  textAlign: "center",
  marginTop: "0px",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "space-between",
  alignItems: "flex-start",
}

const mosaicOne = {
  width: "49%",
  margin: "0px 0px 4px 0px",
  borderRadius: "2px",
}

const mosaicTwo = {
  width: "100%",
}

const bioLink = {
  textDecoration: "none",
  color: "#7395AE",
}

const textList = {
  marginBottom: "30px",
}

export default Bio

import React, { Component } from "react"

import data from '../data/data.json'

import { FaYoutube } from "react-icons/fa"
import { BsChevronRight } from "react-icons/bs"

import RenderMedia from './renderMedia'
import RenderEssay from './renderEssay'

class Media extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: data,
            curStart: 0,
            curEnd: 4,
            curLength: 12,
            selectedType: 'interviews'
        }

        this.interviewsRef = React.createRef();
        this.lecturesRef = React.createRef();
        this.essaysRef = React.createRef();
        this.podcastsRef = React.createRef();

        this.selectInterviews = this.selectInterviews.bind(this);
        this.selectLectures = this.selectLectures.bind(this);
        this.selectEssays = this.selectEssays.bind(this);
        this.selectPodcasts = this.selectPodcasts.bind(this);

        this.selectOlder = this.selectOlder.bind(this);
        this.selectNewer = this.selectNewer.bind(this);
    }

    selectInterviews() {
        this.setState({
            selectedType: 'interviews',
            curLength: 12,
            curStart: 0,
            curEnd: 4
        })

        this.interviewsRef.current.style.color = '#666'
        this.lecturesRef.current.style.color = '#bbb'
        this.essaysRef.current.style.color = '#bbb'
        this.podcastsRef.current.style.color = '#bbb'
    }

    selectLectures() {
        this.setState({
            selectedType: 'lectures',
            curLength: 3,
            curStart: 0,
            curEnd: 4
        })

        this.interviewsRef.current.style.color = '#bbb'
        this.lecturesRef.current.style.color = '#666'
        this.essaysRef.current.style.color = '#bbb'
        this.podcastsRef.current.style.color = '#bbb'
    }

    selectEssays() {
        this.setState({
            selectedType: 'essays',
            curLength: 5,
            curStart: 0,
            curEnd: 20
        })

        this.interviewsRef.current.style.color = '#bbb'
        this.lecturesRef.current.style.color = '#bbb'
        this.essaysRef.current.style.color = '#666'
        this.podcastsRef.current.style.color = '#bbb'
    }

    selectPodcasts() {
        this.setState({
            selectedType: 'podcasts',
            curLength: 5,
            curStart: 0,
            curEnd: 20
        })

        this.interviewsRef.current.style.color = '#bbb'
        this.lecturesRef.current.style.color = '#bbb'
        this.essaysRef.current.style.color = '#bbb'
        this.podcastsRef.current.style.color = '#666'
    }

    selectOlder() {
        console.log(this.state.curStart)
        this.setState({
            curStart: this.state.curStart + 4,
            curEnd: this.state.curEnd + 4
        })
    }

    selectNewer() {
        console.log(this.state.curStart)
        this.setState({
            curStart: this.state.curStart - 4,
            curEnd: this.state.curEnd - 4
        })
    }

    render() {

        const interviewItems = this.state.data.media.interviews.slice(this.state.curStart, this.state.curEnd).map( (element) => {
            return(
                <div style = {mediaElement} class = 'content_element'>
                    <RenderMedia media = {element} windowWidth = {this.props.windowWidth} />
                </div>
            )
        })

        const lectureItems = this.state.data.media.lectures.slice(this.state.curStart, this.state.curEnd).map( (element) => {
            return(
                <div style = {mediaElement} class = 'content_element'>
                    <RenderMedia media = {element} windowWidth = {this.props.windowWidth} />
                </div>
            )
        })

        const essayDemocracyItems = this.state.data.media.essays.democracy.slice(this.state.curStart, this.state.curEnd).map( (element) => {
            return(
                <div style = {essayElement} class = 'content_element'>
                    <RenderEssay essay = {element} type = 'essay' windowWidth = {this.props.windowWidth} />
                </div>
            )
        })

        const essayCovidItems = this.state.data.media.essays.covid.slice(this.state.curStart, this.state.curEnd).map( (element) => {
            return(
                <div style = {essayElement}  class = 'content_element'>
                    <RenderEssay essay = {element} type = 'essay' windowWidth = {this.props.windowWidth} />
                </div>
            )
        })

        const essayItems = <> 
            <h1 style = {subheaderText}>On Democracy</h1>
            { essayDemocracyItems }
            <br />
            <h1 style = {subheaderText}>On COVID-19</h1>
            { essayCovidItems }
        </>

        const podcastItems = this.state.data.media.podcasts.slice(this.state.curStart, this.state.curEnd).map( (element) => {
            return(
                <div style = {essayElement} class = 'content_element'>
                    <RenderEssay essay = {element} type = 'podcast' windowWidth = {this.props.windowWidth} />
                </div>
            )
        })

        let mediaItems;

        if (this.state.selectedType == 'interviews') {
            mediaItems = interviewItems
        } else if (this.state.selectedType == 'lectures') {
            mediaItems = lectureItems
        }  else if (this.state.selectedType == 'podcasts') {
            mediaItems = podcastItems
        } else {
            mediaItems = essayItems
        }

        let navElementStyle;

        if (this.props.windowWidth > 550) {
            navElementStyle = navElement
        } else {
            navElementStyle = navElementMobile
        }

        const startNavBar = 
        <>
        <div style = {navBarStartStyle}>
            <div style = {navElementStyle} onClick = {this.selectOlder}>
                Older
            </div>
        </div>
        </>

        const middleNavBar = 
        <>
        <div style = {navBarStyle}>
            <div style = {navElementStyle} onClick = {this.selectNewer}>
                Newer
            </div>
            <div style = {navElementStyle} onClick = {this.selectOlder}>
                Older
            </div>
        </div>
        </>

        const endNavBar = 
        <>
        <div style = {navBarEndStyle}>
            <div style = {navElementStyle} onClick = {this.selectNewer}>
                Newer
            </div>
        </div>
        </>

        const noNavBar = <div style = {navBarStyle} />

        let navBar;

        if (this.state.curStart == 0) {
            if (this.state.curEnd >= this.state.curLength) {
                navBar = noNavBar;
            } else {
                navBar = startNavBar;
            }
        } else if (this.state.curEnd >= this.state.curLength) {
            navBar = endNavBar;
        } else {
            navBar = middleNavBar;
        }

        if (this.props.windowWidth > 550) {
            return (
                <>
                <div style = {sectionWrapper} id = "media">
                        <div style = {sectionStyle}>
                            <h1 style={headerText}>
                                In The Media
                            </h1>
                            <div style = {selectorContainer}>
                                <div style = {selectorBox} onClick = {this.selectInterviews}>
                                    <h1 style = {selectorText} ref = {this.interviewsRef} > Interviews </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectLectures}>
                                    <h1 style = {selectorTextUnselected} ref = {this.lecturesRef}> Talks </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectEssays}>
                                    <h1 style = {selectorTextUnselected} ref = {this.essaysRef}> Essays </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectPodcasts}>
                                    <h1 style = {selectorTextUnselected} ref = {this.podcastsRef}> Podcasts </h1>
                                </div>
                                </div>
                            <div style = {mediaContainer}>
                                {mediaItems}
                            </div>
                            { navBar }
                        </div>
                    </div>
                </>
                )
        } else {
            return (
                <>
                <div style = {sectionWrapperMobile} id = "media">
                        <div style = {sectionStyleMobile}>
                            <h1 style={headerTextMobile}>
                                In The Media
                            </h1>
                            <div style = {selectorContainerMobile}>
                                <div style = {selectorBox} onClick = {this.selectInterviews}>
                                    <h1 style = {selectorTextMobile} ref = {this.interviewsRef} > Interviews </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectLectures}>
                                    <h1 style = {selectorTextMobileUnselected} ref = {this.lecturesRef}> Talks </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectEssays}>
                                    <h1 style = {selectorTextMobileUnselected} ref = {this.essaysRef}> Essays </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectPodcasts}>
                                    <h1 style = {selectorTextUnselected} ref = {this.podcastsRef}> Podcasts </h1>
                                </div>
                                </div>
                            <div style = {mediaContainer}>
                                {mediaItems}
                            </div>
                            { navBar }
                        </div>
                    </div>
                </>
                )
        }
    }
}
 
const sectionWrapper = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: '0px 20px 0px 50px'
}

const sectionWrapperMobile = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: '0px 20px 0px 20px'
}

const sectionStyle = {
    maxWidth: '1400px',
    margin: '60px auto 0px auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    flexGrow: '1',
}

const sectionStyleMobile = {
    ...sectionStyle,
    margin: '50px auto 0px auto',
}

const headerText = {
    fontFamily: 'Avant Garde',
    fontSize: '36px',
    marginBottom: '40px'
}

const headerTextMobile = {
    ...headerText, 
    fontSize: '32px',
    marginBottom: '34px'
}

const selectorContainer = {
    maxWidth: '380px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
}

const selectorContainerMobile = {
    maxWidth: '320px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
}

const selectorBox = {
    cursor: 'pointer'
}

const selectorText = {
    fontFamily: 'Avant Garde',
    fontSize: '20px',
    marginBottom: '40px',
    color: '#666'
}

const selectorTextUnselected = {
    ...selectorText,
    color: '#BBB'
}

const selectorTextMobile = {
    fontFamily: 'Avant Garde',
    fontSize: '18px',
    marginBottom: '36px',
    color: '#666'
}

const selectorTextMobileUnselected = {
    ...selectorTextMobile,
    color: '#BBB'
}

const mediaContainer = {
    fontFamily: 'Caslon',
    fontSize: '22px',
    textAlign: 'justify',
    width: '100%',
    padding: '10px 0px 0px 0px',
}

const mediaElement = {
    maxWidth: '1000px',
    margin: '6px 0px 20px 0px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    borderRadius: '4px',
    padding: '10px 10px 10px 10px',
}


const subheaderText = {
    fontFamily: 'Avant Garde',
    fontSize: '22px',
    margin: '30px 0px 16px 0px',
    color: '#666',
    margin: '0'
}

const essayElement = {
    maxWidth: '700px',
    margin: '20px 0px 30px 0px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'row nowrap',
    borderRadius: '4px',
    padding: '10px 10px 10px 10px'
}

const navBarStyle = {
    maxWidth: '1000px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    position: 'relative',
    bottom: '0px'
}

const navBarStartStyle = {
    ...navBarStyle,
    justifyContent: 'flex-end'
}

const navBarEndStyle = {
    ...navBarStyle,
    justifyContent: 'flex-start'
}

const navElement = {
    fontFamily: 'Avant Garde',
    fontSize: '20px',
    marginBottom: '40px',
    color: '#666',
    cursor: 'pointer'
}

const navElementMobile = {
    fontFamily: 'Avant Garde',
    fontSize: '18px',
    marginBottom: '30px',
    color: '#666',
    cursor: 'pointer'
}

export default Media

import React, { Component } from "react"

import data from '../data/data.json'

import books from '../images/map.jpg'

import { FaBookOpen } from "react-icons/fa"
import { BsChevronRight } from "react-icons/bs"

import RenderResearch from './renderResearch'
import RenderBookChapter from "./renderBookChapter"

class Research extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: data,
            curStart: 0,
            curEnd: 4,
            curLength: 14,
            selectedType: 'articles'
        }

        this.articlesRef = React.createRef();
        this.bookChaptersRef = React.createRef();

        this.selectArticles = this.selectArticles.bind(this);
        this.selectBookChapters = this.selectBookChapters.bind(this);

        this.selectOlder = this.selectOlder.bind(this);
        this.selectNewer = this.selectNewer.bind(this);
    }

    selectArticles() {
        this.setState({
            selectedType: 'articles',
            curLength: 14,
            curStart: 0,
            curEnd: 4
        })

        this.articlesRef.current.style.color = '#666'
        this.bookChaptersRef.current.style.color = '#bbb'
    }

    selectBookChapters() {
        this.setState({
            selectedType: 'book chapters',
            curLength: 3,
            curStart: 0,
            curEnd: 4
        })

        this.articlesRef.current.style.color = '#bbb'
        this.bookChaptersRef.current.style.color = '#666'
    }

    selectOlder() {
        console.log(this.state.curStart)
        this.setState({
            curStart: this.state.curStart + 4,
            curEnd: this.state.curEnd + 4
        })
    }

    selectNewer() {
        console.log(this.state.curStart)
        this.setState({
            curStart: this.state.curStart - 4,
            curEnd: this.state.curEnd - 4
        })
    }

    render() {

        let buffer;

        if (this.props.windowWidth > 600) {
            buffer = researchElementBuffer
        } else {
            buffer = researchElementBufferMobile
        }

        const researchArticles = this.state.data.research.articles.slice(this.state.curStart, this.state.curEnd).map((element) => {
            return(
                <div style = {buffer}>
                    <div style = {researchElement} class = 'content_element'>
                        <RenderResearch research = {element} windowWidth = {this.props.windowWidth} />
                    </div>
                </div>
            )
        })

        const researchBookChapters = this.state.data.research.book_chapters.slice(this.state.curStart, this.state.curEnd).map((element) => {
            return(
                <div style = {buffer}>
                    <div style = {researchElement} class = 'content_element'>
                        <RenderBookChapter research = {element} windowWidth = {this.props.windowWidth} />
                    </div>
                </div>
            )
        })

        let researchItems;

        if (this.state.selectedType == 'articles') {
            researchItems = researchArticles
        } else {
            researchItems = researchBookChapters
        }

        let navElementStyle;

        if (this.props.windowWidth > 550) {
            navElementStyle = navElement
        } else {
            navElementStyle = navElementMobile
        }

        const startNavBar = 
        <>
        <div style = {navBarStartStyle}>
            <div style = {navElementStyle} onClick = {this.selectOlder}>
                Older
            </div>
        </div>
        </>

        const middleNavBar = 
        <>
        <div style = {navBarStyle}>
            <div style = {navElementStyle} onClick = {this.selectNewer}>
                Newer
            </div>
            <div style = {navElementStyle} onClick = {this.selectOlder}>
                Older
            </div>
        </div>
        </>

        const endNavBar = 
        <>
        <div style = {navBarEndStyle}>
            <div style = {navElementStyle} onClick = {this.selectNewer}>
                Newer
            </div>
        </div>
        </>

        const noNavBar = <div style = {navBarStyle} />

        let navBar;

        if (this.state.curStart == 0) {
            if (this.state.curEnd >= this.state.curLength) {
                navBar = noNavBar;
            } else {
                navBar = startNavBar;
            }
        } else if (this.state.curEnd >= this.state.curLength) {
            navBar = endNavBar;
        } else {
            navBar = middleNavBar;
        }

        if (this.props.windowWidth > 826) {
            return (
                <div style = {sectionWrapper} id = "research">
                    <div style = {sectionStyle}>
                        <div style = {mainContentStyle}>
                            <h1 style={headerText}>
                                Research
                            </h1>
                            <div style = {selectorContainer}>
                                <div style = {selectorBox} onClick = {this.selectArticles}>
                                    <h1 style = {selectorText} ref = {this.articlesRef} > Articles </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectBookChapters}>
                                <h1 style = {selectorTextUnselected} ref = {this.bookChaptersRef}> Book Chapters </h1>
                                </div>
                            </div>
                            <div style = {researchContainer}>
                                {researchItems}
                            </div>
                            { navBar }
                        </div>
                    </div>
                    <div style = {sidebarStyle}>
                        <img src = {books} alt = "Books" style = {imageStyle} />
                    </div>  
                </div>
            )
        } else {
            return (
               <div style = {sectionWrapper} id = "research">
                    <div style = { mobileSpace } >

                    </div>
                    <div style = {sectionStyleMobile}>
                        <div style = {mainContentStyleMobile}>
                            <h1 style={headerTextMobile}>
                                Research
                            </h1>
                            <div style = {selectorContainerMobile}>
                                <div style = {selectorBox} onClick = {this.selectArticles}>
                                    <h1 style = {selectorTextMobile} ref = {this.articlesRef} > Articles </h1>
                                </div>
                                <div style = {selectorBox} onClick = {this.selectBookChapters}>
                                <h1 style = {selectorTextMobileUnselected} ref = {this.bookChaptersRef}> Book Chapters </h1>
                                </div>
                            </div>
                            <div style = {researchContainerMobile}>
                                {researchItems}
                            </div>
                            { navBar }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

const sectionWrapper = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
}

const sectionStyle = {
    height: '860px',
    minWidth: '766px',
    maxWidth: '100%',
    margin: '60px 60px 0px 0px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    flexShrink: '0',
    flexGrow: '1'
}

const sectionStyleMobile = {
    width: '100%',
    margin: '50px 60px 0px 0px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
}

const mobileSpace = {
    height: '100%',
    width: '60px',
    flexShrink: '0',
    flexGrow: '1',
    backgroundColor: 'orange',
}

const mainContentStyle = {
    maxWidth: '666px',
    height: '800px',
}

const mainContentStyleMobile = {
    maxWidth: '666px',
}

const headerText = {
    fontFamily: 'Avant Garde',
    fontSize: '36px',
    marginBottom: '40px'
}

const headerTextMobile = {
    ...headerText, 
    fontSize: '32px',
    marginBottom: '34px'
}

const selectorContainer = {
    maxWidth: '240px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
}

const selectorContainerMobile = {
    maxWidth: '220px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
}

const selectorBox = {
    cursor: 'pointer'
}

const selectorText = {
    fontFamily: 'Avant Garde',
    fontSize: '20px',
    marginBottom: '40px',
    color: '#666'
}

const selectorTextUnselected = {
    ...selectorText,
    color: '#BBB'
}

const selectorTextMobile = {
    fontFamily: 'Avant Garde',
    fontSize: '18px',
    marginBottom: '36px',
    color: '#666'
}

const selectorTextMobileUnselected = {
    ...selectorTextMobile,
    color: '#BBB'
}

const researchContainer = {
    fontFamily: 'Caslon',
    height: '710px',
    fontSize: '22px',
    textAlign: 'justify',
    width: '100%',
    padding: '0px 0px 0px 0px',
    position: 'relative'
}

const researchContainerMobile = {
    fontFamily: 'Caslon',
    fontSize: '22px',
    textAlign: 'justify',
    width: '100%',
    padding: '0px 0px 0px 0px',
    position: 'relative'
}

const researchElementBuffer = {
    width: '100%',
    margin: '6px auto 40px auto',
}

const researchElementBufferMobile = {
    width: '100%',
    margin: '6px auto 20px auto',
}

const researchElement = {
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    // backgroundColor: '#f5f5f5',
    padding: '10px 10px 10px 10px',
    borderRadius: '4px'
}

const sidebarStyle = {
    maxWidth: '50%',
    height: '920px',
    overflow: 'hidden',
    flexShrink: '1'
}

const imageStyle = {
    height: '100%',
    objectFit: 'cover',
}

const navBarStyle = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    position: 'relative',
    bottom: '0px'
}

const navBarStartStyle = {
    ...navBarStyle,
    justifyContent: 'flex-end'
}

const navBarEndStyle = {
    ...navBarStyle,
    justifyContent: 'flex-start'
}

const navElement = {
    fontFamily: 'Avant Garde',
    fontSize: '20px',
    marginBottom: '40px',
    color: '#666',
    cursor: 'pointer'
}

const navElementMobile = {
    fontFamily: 'Avant Garde',
    fontSize: '18px',
    marginBottom: '30px',
    color: '#666',
    cursor: 'pointer'
}

export default Research
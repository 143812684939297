import React, { Component } from "react"

import { FaBookOpen } from "react-icons/fa"
import { BsChevronRight } from "react-icons/bs"

import unpacking from '../files/unpacking.pdf'

function RenderReadText({pdf, windowWidth}) {
    if (pdf) {
        if (windowWidth > 550) {
            return(
                <p style = {resLink}>
                        Read PDF <BsChevronRight 
                                    style = {{ 
                                        height: '18px', 
                                        position: 'relative', 
                                        top: '3px',
                                        right: '4px'
                                        }} />
                    </p>
            )
        } else {
            return(
                <p style = {resLinkMobile}>
                        Read PDF <BsChevronRight 
                                    style = {{ 
                                        height: '14px', 
                                        position: 'relative', 
                                        top: '2px',
                                        right: '4px'
                                        }} />
                    </p>
            )
        }
    } else {
        if (windowWidth > 550) {
            return(
                <p style = {resLink}>
                        Read more <BsChevronRight 
                                    style = {{ 
                                        height: '18px', 
                                        position: 'relative', 
                                        top: '3px',
                                        right: '4px'
                                        }} />
                    </p>
            )
        } else {
            return(
                <p style = {resLinkMobile}>
                        Read more <BsChevronRight 
                                    style = {{ 
                                        height: '14px', 
                                        position: 'relative', 
                                        top: '2px',
                                        right: '4px'
                                        }} />
                    </p>
            )
        }
    }
}


function RenderRest({research, pdf, windowWidth}) {
    if (windowWidth > 550) {
        return(
        <>
            <div style = {rcTitle}>
                <p style = {resTitle}>
                    {research.title}
                </p>
                <p style = {resJournal}>
                    Chapter from {research.book}, {research.year}
                </p>
            </div>
            <div style = {rcBottom}>
                <div style = {rcDescription}>
                    <p style = {resAuthors}>
                        With {research.authors}
                    </p>
                    
                </div>
                <FaBookOpen style = {resIcon} />
                <div style = {rcLink}>
                    <RenderReadText pdf = {pdf} windowWidth = {windowWidth} />
                </div>
            </div>
        </>
        )
    } else {
        return(
            <>
                <div style = {rcTitle}>
                    <p style = {resTitleMobile}>
                        {research.title}
                    </p>
                    <p style = {resJournalMobile}>
                        Chapter from {research.book}, {research.year}
                    </p>
                </div>
                <div style = {rcBottom}>
                    <div style = {rcDescription}>
                        <p style = {resAuthorsMobile}>
                            With {research.authors}
                        </p>
                        
                    </div>
                    <FaBookOpen style = {resIcon} />
                    <div style = {rcLink}>
                        <RenderReadText pdf = {pdf} windowWidth = {windowWidth} />
                    </div>
                </div>
            </>
            )
    }
}

function PDFLink({research, windowWidth}) {
    if (research.pdf == "none") {
        return(
            <a href = {research.link} target = "_blank" style = {{textDecoration: 'none', color: 'black'}}>
                <RenderRest research = {research} pdf = {false} windowWidth = {windowWidth} />
            </a>
        )
    } else {
        if (research.pdf == "unpacking") {
            return(
                <a href = {unpacking} target = "_blank" style = {{textDecoration: 'none', color: 'black'}}>
                <RenderRest research = {research} pdf = {true} windowWidth = {windowWidth} />
            </a>
            )
        } else {
            return(
                <a href = {unpacking} target = "_blank" style = {{textDecoration: 'none', color: 'black'}}>
                <RenderRest research = {research} pdf = {true}windowWidth = {windowWidth} />
            </a>
            )
        }
    }
}

function RenderBookChapter({research, windowWidth}) {
    return(
        <PDFLink research = {research} windowWidth = {windowWidth}  />
    )
}


const rcTitle = {
}

const resTitle = {
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'left',
    position: 'relative',
    margin: '0',
    marginBottom: '0px'
}

const resTitleMobile = {
    ...resTitle,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '6px'
}

const rcBottom = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
    width: '100%'
}

const rcDescription = {
    paddingTop: '10px',
    flexGrow: '1'
}

const resAuthors = {
    color: '#777',
    position: 'relative',
    margin: '0',
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '6px',
    textAlign: 'left'
}

const resAuthorsMobile = {
    ...resAuthors,
    fontSize: '14px',
    lineHeight: '12px',
    marginBottom: '6px',
}

const resJournal = {
    color: '#777',
    position: 'relative',
    margin: '0',
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    lineHeight: '20px',
    fontStyle: 'italic',
    fontWeight: '600',
    marginBottom: '18px',
    textAlign: 'left'
}

const resJournalMobile = {
    resJournal,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '16px'
}

const rcLink = {
    width: '200px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-end',
    marginLeft: '10px'
}

const resLink = {
    position: 'relative',
    color: '#7395AE',
    fontFamily: 'Avant Garde',
    fontSize: '16px',
    textAlign: 'right',
    margin: '0'
}

const resLinkMobile = {
    position: 'relative',
    color: '#7395AE',
    fontFamily: 'Avant Garde',
    fontSize: '14px',
    textAlign: 'right',
    margin: '0'
}

const resIcon = {
    position: 'absolute',
    left: '-48px',
    top: '17px',
    color: '#7395AE'
}

export default RenderBookChapter;

import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Bio from "../components/bio/bio"
import Books from "../components/books"
import Research from "../components/research"
import Media from "../components/media"
import Podcast from "../components/podcast"
import Contact from "../components/contact"

import ScrollUpButton from "../components/scrollUpButton"

import SEO from "../components/seo"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0,
      windowWidth: 0,
      windowHeight: 0,
    }

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
    window.addEventListener("scroll", this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll)
    window.removeEventListener("resize", this.updateDimensions)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    this.setState({
      scrollPosition: scrolled,
    })
  }

  updateDimensions() {
    let windowWidth =
      typeof window !== "undefined" ? window.visualViewport.width : 0
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0

    this.setState({ windowWidth, windowHeight })
  }

  render() {
    const { windowWidth } = this.state

    return (
      <>
        <div style={topDiv} id="top"></div>
        <Layout rootPage={true} windowWidth={windowWidth}>
          <SEO title="Home" />
          <ScrollUpButton
            scrollPosition={this.state.scrollPosition}
            windowWidth={windowWidth}
          />
          <Bio windowWidth={windowWidth} />
          <Books id="books" windowWidth={windowWidth} />
          <Research id="research" windowWidth={windowWidth} />
          <Podcast windowWidth={windowWidth} />
          <Media id="media" windowWidth={windowWidth} />
          <Contact windowWidth={windowWidth} />
        </Layout>
      </>
    )
  }
}

const topDiv = {
  height: "0px",
  width: "0px",
  position: "absolute",
  top: "0px",
}

const sidebarContainerStyle = {
  width: "100%",
  height: "100%",
  position: "fixed",
  top: "0",
  overflow: "hidden",
}

const sidebarStyle = {
  position: "absolute",
  height: "100%",
  objectFit: "cover",
}

export default IndexPage

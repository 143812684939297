import React, { Component } from "react"

import { Spring } from "react-spring/renderprops"

import insideCountries from "../images/insidecountries.jpg"
import democratsAndAutocrats from "../images/democratsandautocrats.jpg"

import { BsChevronRight } from "react-icons/bs"

const icText =
  "Although comparative politics is conventionally seen as the study of politics across countries, the field has a longstanding and increasingly prominent tradition in national contexts; focusing on subnational units, institutions, actors and processes. This book offers the first comprehensive assessment of the substantive, theoretical, and methodological contributions. With empirical chapters from across the contemporary Global South, including India, Mexico, and China, as well as Russia, the contributors show how subnational research provides useful insights about substantive themes in political science, from regimes and representation, to states and security, to social and economic development. In addition to methodological chapters with specific guidance about best practices for doing subnational research, this volume also proposes a set of strategies for subnational research, assesses their strengths and weaknesses, and offers illustrative empirical applications."

const daaText =
  "Even though countries in Latin America transitioned to democracy, many citizens residing in peripheral regions continue to live under undemocratic rule. Democrats and Autocrats studies the existence of subnational undemocratic regimes (SURs) alongside national democratic regimes in Argentina and Mexico. Contrary to conventional wisdom, the book argues that there are multiple pathways for SURs reproduction within democratic countries. It shows instead the existence of multiple, within-country, pathways that lead to SUR reproduction. The book is premised on the notion that SURs within countries not only differ among each other but that they maintain different relations with the federal government, which is why they are reproduced differently. Using a multi-method approach, Democrats and Autocrats shows that, within-country, alternative trajectories of SUR continuity in Argentina and Mexico result first and foremost from the capacity (or lack thereof) of national incumbents to wield power over SURs and subnational autocrats."

function RenderBook({
  selected,
  selectLeft,
  selectRight,
  dimLeftImage,
  dimRightImage,
  brightenLeftImage,
  brightenRightImage,
  leftImageRef,
  rightImageRef,
  dimText,
  brightenText,
  textRef,
  windowWidth,
}) {
  if (selected == "left") {
    if (windowWidth > 600) {
      return (
        <>
          <div style={imageContainerStyle}>
            <a
              href="https://www.amazon.com/Inside-Countries-Subnational-Research-Comparative/dp/1108721702/ref=sr_1_1?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-1"
              target="_blank"
              style={imageLinkStyle}
            >
              <img
                src={insideCountries}
                alt="Inside Countries Book"
                style={imageStyle}
                onClick={selectLeft}
                onMouseOver={dimLeftImage}
                onMouseOut={brightenLeftImage}
                ref={leftImageRef}
              />
            </a>
            <img
              src={democratsAndAutocrats}
              alt="Democrats and Autocrats Book"
              style={secondImageStyle}
              onClick={selectRight}
            />
          </div>
          <div style={divider}></div>
          <div style={textBoxStyle}>
            <p style={bookTitleText}>Inside Countries (2019)</p>
            <Spring to={{ ...mainTextWrapper, height: "auto" }}>
              {props => (
                <div style={props}>
                  <p style={mainText}>{icText}</p>
                  <div style={ctaHolder}>
                    <a
                      href="https://www.amazon.com/Inside-Countries-Subnational-Research-Comparative/dp/1108721702/ref=sr_1_1?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-1"
                      target="_blank"
                      style={linkStyle}
                    >
                      <p
                        style={ctaStyle}
                        ref={textRef}
                        onMouseOver={dimText}
                        onMouseOut={brightenText}
                      >
                        Visit Amazon Page{" "}
                        <BsChevronRight
                          style={{
                            height: "18px",
                            position: "relative",
                            top: "3px",
                            right: "5px",
                          }}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </Spring>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={imageContainerMobile}>
            <a
              href="https://www.amazon.com/Inside-Countries-Subnational-Research-Comparative/dp/1108721702/ref=sr_1_1?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-1"
              target="_blank"
              style={imageLinkMobile}
            >
              <img
                src={insideCountries}
                alt="Inside Countries Book"
                style={imageStyleMobile}
                onClick={selectLeft}
                onMouseOver={dimLeftImage}
                onMouseOut={brightenLeftImage}
                ref={leftImageRef}
              />
            </a>
            <img
              src={democratsAndAutocrats}
              alt="Democrats and Autocrats Book"
              style={secondImageStyleMobile}
              onClick={selectRight}
            />
          </div>

          <div style={textBoxStyle}>
            <p style={bookTitleTextMobile}>Inside Countries (2019)</p>
            <Spring to={{ ...mainTextWrapper, height: "auto" }}>
              {props => (
                <div style={props}>
                  <p style={mainTextMobile}>{icText}</p>
                  <div style={ctaHolder}>
                    <a
                      href="https://www.amazon.com/Inside-Countries-Subnational-Research-Comparative/dp/1108721702/ref=sr_1_1?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-1"
                      target="_blank"
                      style={linkStyle}
                    >
                      <p
                        style={ctaStyleMobile}
                        ref={textRef}
                        onMouseOver={dimText}
                        onMouseOut={brightenText}
                      >
                        Visit Amazon Page{" "}
                        <BsChevronRight
                          style={{
                            height: "18px",
                            position: "relative",
                            top: "3px",
                            right: "5px",
                          }}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </Spring>
          </div>
        </>
      )
    }
  } else {
    if (windowWidth > 600) {
      return (
        <>
          <div style={imageContainerStyle}>
            <img
              src={insideCountries}
              alt="Inside Countries Book"
              style={secondImageStyle}
              onClick={selectLeft}
            />
            <a
              href="https://www.amazon.com/Democrats-Autocrats-Subnational-Undemocratic-Transformations/dp/0198706863/ref=sr_1_2?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-2"
              target="_blank"
              style={imageLinkStyle}
            >
              <img
                src={democratsAndAutocrats}
                alt="Democrats and Autocrats Book"
                style={imageStyle}
                onClick={selectRight}
                onMouseOver={dimRightImage}
                onMouseOut={brightenRightImage}
                ref={rightImageRef}
              />
            </a>
          </div>
          <div style={divider}></div>
          <div style={textBoxStyle}>
            <p style={bookTitleText}>Democrats and Autocrats (2015)</p>
            <Spring
              from={{ ...mainTextWrapper, height: "0px" }}
              to={{ ...mainTextWrapper, height: "auto" }}
            >
              {props => (
                <div style={props}>
                  <p style={mainText}>{daaText}</p>
                  <div style={ctaHolder}>
                    <a
                      href="https://www.amazon.com/Democrats-Autocrats-Subnational-Undemocratic-Transformations/dp/0198706863/ref=sr_1_2?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-2"
                      target="_blank"
                      style={linkStyle}
                    >
                      <p
                        style={ctaStyle}
                        ref={textRef}
                        onMouseOver={dimText}
                        onMouseOut={brightenText}
                      >
                        Visit Amazon Page{" "}
                        <BsChevronRight
                          style={{
                            height: "18px",
                            position: "relative",
                            top: "3px",
                            right: "5px",
                          }}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </Spring>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={imageContainerMobile}>
            <img
              src={insideCountries}
              alt="Inside Countries Book"
              style={secondImageStyleMobile}
              onClick={selectLeft}
            />
            <a
              href="https://www.amazon.com/Democrats-Autocrats-Subnational-Undemocratic-Transformations/dp/0198706863/ref=sr_1_2?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-2"
              target="_blank"
              style={imageLinkMobile}
            >
              <img
                src={democratsAndAutocrats}
                alt="Democrats and Autocrats Book"
                style={imageStyleMobile}
                onClick={selectRight}
                onMouseOver={dimRightImage}
                onMouseOut={brightenRightImage}
                ref={rightImageRef}
              />
            </a>
          </div>
          <div style={divider}></div>
          <div style={textBoxStyle}>
            <p style={bookTitleTextMobile}>Democrats and Autocrats (2015)</p>
            <Spring
              from={{ ...mainTextWrapper, height: "0px" }}
              to={{ ...mainTextWrapper, height: "auto" }}
            >
              {props => (
                <div style={props}>
                  <p style={mainTextMobile}>{daaText}</p>
                  <div style={ctaHolder}>
                    <a
                      href="https://www.amazon.com/Democrats-Autocrats-Subnational-Undemocratic-Transformations/dp/0198706863/ref=sr_1_2?dchild=1&qid=1609093129&refinements=p_27%3AAgustina+Giraudy&s=books&sr=1-2"
                      target="_blank"
                      style={linkStyle}
                    >
                      <p
                        style={ctaStyleMobile}
                        ref={textRef}
                        onMouseOver={dimText}
                        onMouseOut={brightenText}
                      >
                        Visit Amazon Page{" "}
                        <BsChevronRight
                          style={{
                            height: "18px",
                            position: "relative",
                            top: "3px",
                            right: "5px",
                          }}
                        />
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </Spring>
          </div>
        </>
      )
    }
  }
}

class Book extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: "left",
    }
    this.selectLeft = this.selectLeft.bind(this)
    this.selectRight = this.selectRight.bind(this)

    this.dimLeftImage = this.dimLeftImage.bind(this)
    this.brightenLeftImage = this.brightenLeftImage.bind(this)
    this.dimRightImage = this.dimRightImage.bind(this)
    this.brightenRightImage = this.brightenRightImage.bind(this)

    this.dimText = this.dimText.bind(this)
    this.brightenText = this.brightenText.bind(this)

    this.leftImageRef = React.createRef()
    this.rightImageRef = React.createRef()
    this.textRef = React.createRef()
  }

  selectLeft() {
    this.setState({
      selected: "left",
    })
  }

  selectRight() {
    this.setState({
      selected: "right",
    })
  }

  dimLeftImage() {
    this.leftImageRef.current.style.opacity = "0.9"
  }

  brightenLeftImage() {
    this.leftImageRef.current.style.opacity = "1.0"
  }

  dimRightImage() {
    this.rightImageRef.current.style.opacity = "0.9"
  }

  brightenRightImage() {
    this.rightImageRef.current.style.opacity = "1.0"
  }

  dimText() {
    this.textRef.current.style.color = "#a8e4e6"
  }

  brightenText() {
    this.textRef.current.style.color = "#cffeff"
  }

  render() {
    if (this.props.windowWidth > 1250) {
      return (
        <div style={sectionWrapper} id="books">
          <div style={sectionStyle}>
            <h1 style={headerText}>Books</h1>
            <div style={mainContainerStyle}>
              <RenderBook
                selected={this.state.selected}
                selectLeft={this.selectLeft}
                selectRight={this.selectRight}
                dimLeftImage={this.dimLeftImage}
                dimRightImage={this.dimRightImage}
                brightenLeftImage={this.brightenLeftImage}
                brightenRightImage={this.brightenRightImage}
                leftImageRef={this.leftImageRef}
                rightImageRef={this.rightImageRef}
                dimText={this.dimText}
                brightenText={this.brightenText}
                textRef={this.textRef}
                windowWidth={this.props.windowWidth}
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={sectionWrapperMobile} id="books">
          <div style={sectionStyle}>
            <h1 style={headerTextMobile}>Books</h1>
            <div style={mainContainerMobile}>
              <RenderBook
                selected={this.state.selected}
                selectLeft={this.selectLeft}
                selectRight={this.selectRight}
                dimLeftImage={this.dimLeftImage}
                dimRightImage={this.dimRightImage}
                brightenLeftImage={this.brightenLeftImage}
                brightenRightImage={this.brightenRightImage}
                leftImageRef={this.leftImageRef}
                rightImageRef={this.rightImageRef}
                dimText={this.dimText}
                brightenText={this.brightenText}
                textRef={this.textRef}
                windowWidth={this.props.windowWidth}
              />
            </div>
          </div>
        </div>
      )
    }
  }
}

const sectionWrapper = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  padding: "30px 80px 20px 80px",
  backgroundColor: "#3b576b",
  color: "white",
}

const sectionWrapperMobile = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "30px 20px 0px 20px",
  backgroundColor: "#3b576b",
  color: "white",
}

const sectionStyle = {
  maxWidth: "1400px",
  margin: "0px auto 0px auto",
}

const headerText = {
  fontFamily: "Avant Garde",
  fontSize: "36px",
  margin: "20px 0px 40px 0px",
}

const headerTextMobile = {
  ...headerText,
  fontSize: "32px",
  marginBottom: "30px",
}

const mainContainerStyle = {
  display: "flex",
  flexFlow: "row nowrap",
}

const mainContainerMobile = {
  display: "flex",
  flexFlow: "column nowrap",
  maxWidth: "700px",
}

const divider = {
  height: "100%",
  width: "140px",
  flexShrink: "0",
}

const imageContainerStyle = {
  width: "500px",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  margin: "0px 0px 0px 0px",
  flexShrink: "0",
  flexGrow: "1",
}

const imageContainerMobile = {
  minWidth: "300px",
  maxWidth: "540px",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "space-between",
  margin: "0px 0px 0px 0px",
  flexShrink: "0",
  flexGrow: "1",
}

const imageLinkStyle = {
  height: "360px",
}

const imageLinkMobile = {
  height: "220px",
}

const imageStyle = {
  height: "360px",
  opacity: "1.0",
  cursor: "pointer",
  backgroundColor: "red",
  margin: "0px 0px 0px 0px",
}

const imageStyleMobile = {
  ...imageStyle,
  height: "220px",
}

const secondImageStyle = {
  height: "360px",
  opacity: "0.5",
  cursor: "pointer",
}

const secondImageStyleMobile = {
  ...secondImageStyle,
  height: "220px",
}

const textBoxStyle = {
  marginLeft: "0px",
  marginBottom: "20px",
}

const textBoxMobile = {
  marginLeft: "0px",
  marginBottom: "20px",
}

const bookTitleText = {
  fontFamily: "Avant Garde",
  fontSize: "26px",
  fontWeight: "700",
  position: "relative",
  top: "9px",
}

const bookTitleTextMobile = {
  ...bookTitleText,
  fontSize: "22px",
  marginBottom: "16px",
  lineHeight: "24px",
}

const mainTextWrapper = {
  overflow: "hidden",
}

const mainText = {
  fontFamily: "Caslon",
  fontSize: "20px",
  textAlign: "left",
}

const mainTextMobile = {
  fontFamily: "Caslon",
  fontSize: "18px",
  textAlign: "left",
  lineHeight: "22px",
}

const ctaHolder = {
  position: "relative",
  bottom: "16px",
  width: "200px",
}

const ctaStyle = {
  fontFamily: "Avant Garde",
  fontSize: "18px",
  textAlign: "justify",
  color: "#cffeff",
  width: "200px",
}

const ctaStyleMobile = {
  fontFamily: "Avant Garde",
  fontSize: "18px",
  textAlign: "justify",
  color: "#cffeff",
  width: "200px",
}

const linkStyle = {
  textDecoration: "none",
  width: "200px",
  height: "26px",
}

export default Book
